export const style = {
  position: 'fixed' as 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 650,
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
  '@media (max-width: 767px)': {
    maxHeight: '400px',
    width: 300,
    p: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
};
