type DataSearch = {
  alias?: string;
  brandModel?: string;
  description?: string;
  id?: string;
  kind?: string;
  name?: string;
  title?: string;
};

export const linkTo = (kind: DataSearch) => {
  const resultString = (kind.kind || '').replace(/^advert/, '');
  const lowerCasedResult =
    resultString.charAt(0).toLowerCase() + resultString.slice(1);

  if (resultString === 'category') {
    return `/marketplace?category=${kind.alias}`;
  } else if (resultString === 'organization') {
    return `/companies/${kind.id}`;
  } else if (lowerCasedResult) {
    return `/marketplace/advert/${lowerCasedResult}/${kind.id}`;
  } else {
    return `/`;
  }
};
