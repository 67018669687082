import React from 'react';
import { useEffect, useState } from 'react';
import {
  Container,
  TextField,
  List,
  ListItem,
  Typography,
  Box,
  IconButton,
  Link,
  CardMedia,
  Divider,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import WebApi from 'shared/services';
import * as fetchImport from 'isomorphic-unfetch';
import { capitalizeFirstLetter } from 'shared/helper';
import { linkTo } from 'shared/helper/search';
import theme from 'shared/theme';
import { Picture } from 'entities/Picture';
import { imageTypeAlias } from 'shared/constants';

const fetch = (fetchImport.default ||
  fetchImport) as typeof fetchImport.default;
type DataSearched = {
  alias: string;
  brandModel: string;
  description: string;
  id: string;
  kind: string;
  name: string;
  title: string;
};
export const UI = ({
  slice,
  text,
  width,
  pl,
  pr,
}: {
  slice?: number;
  text?: string;
  width: string;
  pl: string | number;
  pr: string | number;
}) => {
  const [inputText, setInputText] = useState('');
  const [dataSearch, setDataSearch] = useState<DataSearched[]>([]);
  const [showList, setShowList] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  interface CategoryImages {
    [alias: string]: string;
  }

  const categoryImages: CategoryImages = {
    specialMachinery: 'images/specialMachinery.png',
    realty: 'images/realty.png',
    buildingMaterial: 'images/buildingMaterial.png',
    product: 'images/product.png',
    equipment: 'images/equipment.png',
    workService: 'images/workService.png',
  };

  // значение инпута в нижний регистр
  const handleTextTyping = (e: any) => {
    let lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  useEffect(() => {
    const getSearchedData = async () => {
      setIsLoading(true);
      const { err, data: searchData } = await WebApi().Search.create({
        query: inputText || text,
      });
      setDataSearch(searchData.data.data);
      setIsLoading(false);
    };

    if (inputText?.trim().length > 3) {
      getSearchedData();
      setShowBtn(true);
      setShowList(true);
    } else {
      setShowBtn(false);
      setDataSearch([]);
      setShowList(false);
    }
  }, [inputText, text]);

  const getKindLabel = (item: DataSearched): string => {
    if (item.kind === 'category') return 'Категория';
    if (item.kind === 'organization') return 'Компания';
    if (item.kind.includes('advert')) return 'Объявление';
    return '';
  };
  return (
    <>
      <Container sx={{ width: width, pl: pl ? pl : 0, pr: pr ? pr : 0 }}>
        <Box sx={{ position: 'relative' }}>
          <TextField
            id="searchInput"
            fullWidth
            value={inputText}
            placeholder="Поиск..."
            variant="outlined"
            size="small"
            onChange={handleTextTyping}
            sx={{
              backgroundColor: theme.palette.secondary.light,
              borderRadius: '4px',
              [theme.breakpoints.down('sm')]: { mt: 2 },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.grey[900],
                },
              },

              '& input::placeholder': {
                color: theme.palette.grey[900],
                '@media (max-width: 500px)': {
                  fontSize: '13px',
                },
              },
            }}
            InputProps={{
              inputProps: { style: { fontSize: '15px' } },
              startAdornment: (
                <SearchIcon
                  sx={{
                    color: theme.palette.grey[500],
                    marginRight: '4px',
                    // '@media (max-width: 500px)': {
                    //   display: 'none',
                    // },
                  }}
                />
              ),
              endAdornment: inputText && (
                <Box display={'flex'} alignItems={'center'}>
                  <IconButton
                    aria-label="clearLabel"
                    onClick={() => setInputText('')}
                  >
                    <ClearIcon sx={{ fontSize: 16 }} />
                  </IconButton>
                  <Divider
                    sx={{ height: 28, ml: 1, mr: 1 }}
                    orientation="vertical"
                  />
                  <Link
                    href={`/search?result=${inputText.trim()}`}
                    sx={{
                      color: theme.palette.grey[900],
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      minWidth: '100%',
                      minHeight: '100%',
                    }}
                  >
                    <Button
                      size="small"
                      sx={{
                        borderRadius: 2,
                        fontWeight: 600,
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.grey[800],
                        ':hover': {
                          backgroundColor: theme.palette.secondary.main,
                          color: theme.palette.grey[900],
                          opacity: 0.7,
                        },
                      }}
                    >
                      Найти
                    </Button>
                  </Link>
                </Box>
              ),
            }}
          />
          {showList && !isLoading ? (
            <List
              sx={{
                display: 'block',
                position: 'absolute',
                margin: '5px 0 10px',
                paddingTop: 0.5,
                paddingBottom: 0.5,
                backgroundColor: '#fff',
                zIndex: 201,
                overflowY: 'auto',
                borderRadius: '10px',
                boxShadow: '3px 3px 10px grey',
                maxHeight: '40vh',
                width: '100%',
              }}
            >
              {dataSearch && dataSearch.length > 0 ? (
                dataSearch.slice(0, slice).map((item) => (
                  <ListItem
                    key={item.id}
                    sx={{
                      padding: '0 15px',
                      paddingTop: '2px',
                      margin: 0,
                      height: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <Link
                      href={linkTo(item)}
                      sx={{
                        color: theme.palette.grey[900],
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        minWidth: '100%',
                        minHeight: '100%',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <Box
                          sx={{ mr: 2, display: 'flex', alignItems: 'center' }}
                        >
                          {getKindLabel(item) === 'Компания' && (
                            <Picture
                              width={32}
                              height={32}
                              objectFit={'cover'}
                              round={0}
                              companyId={item.id}
                              imageType={imageTypeAlias.organizationLogo}
                            />
                          )}
                          {getKindLabel(item) === 'Объявление' && (
                            <Picture
                              width={32}
                              height={32}
                              objectFit={'cover'}
                              round={0}
                              advertId={item.id}
                              imageType={imageTypeAlias.advert}
                            />
                          )}
                          {/* Надо будет доработать после правок на Бэке */}
                          {item.kind === 'category' &&
                            categoryImages[item.alias] && (
                              <CardMedia
                                component="img"
                                alt="Category Image"
                                sx={{ width: 32, height: 32 }}
                                src={categoryImages[item.alias]}
                              />
                            )}
                        </Box>
                        <Box>
                          <Typography
                            variant="body1"
                            component="span"
                            sx={{
                              color: theme.palette.grey[900],
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              // fontWeight: '600',
                            }}
                          >
                            {capitalizeFirstLetter(item.name) ||
                              capitalizeFirstLetter(item.title)}
                          </Typography>

                          <Typography
                            component="span"
                            sx={{
                              color: theme.palette.grey[500],
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              fontWeight: 700,
                            }}
                          >
                            {getKindLabel(item)}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </ListItem>
                ))
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ pl: 2, fontWeight: 500 }}
                >
                  Ничего не найдено!
                </Typography>
              )}
              {dataSearch && dataSearch.length > 5 && slice && (
                <ListItem
                  sx={{
                    padding: '0 15px',
                    paddingTop: '2px',
                    margin: 0,
                    height: '42px',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <Link
                    href={`/search?result=${inputText.trim()}`}
                    sx={{
                      color: theme.palette.grey[900],
                      display: 'flex',
                      cursor: 'pointer',
                      alignItems: 'center',
                      minWidth: '100%',
                      minHeight: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      sx={{
                        color: theme.palette.grey[900],
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: 'vertical',
                        fontWeight: 600,
                      }}
                      minWidth={'100%'}
                      minHeight={'100%'}
                    >
                      Показать все результаты
                    </Typography>
                  </Link>
                </ListItem>
              )}
            </List>
          ) : null}
        </Box>
      </Container>
    </>
  );
};
