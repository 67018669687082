import React, { useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import theme from 'shared/theme';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const UI = ({ setOpen, title, categories, onSelect }: any) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleNodeSelect = (event: any, nodeId: string) => {
    // Проверяем, есть ли у выбранной категории вложенные категории
    const selectedNode = findCategory(categories, nodeId);

    if (
      selectedNode &&
      selectedNode.categories &&
      selectedNode.categories.length > 0
    ) {
      // Если есть вложенные категории, не обрабатываем выбор
      return;
    }
    setSelectedCategory(nodeId);
  };
  // Некактивная кнопка, если выбран вернхий уровень категорий
  const hasNestedCategories = (nodeId: string): boolean => {
    const selectedNode = findCategory(categories, nodeId);
    return (
      selectedNode &&
      selectedNode.categories &&
      selectedNode.categories.length > 0
    );
  };
  const findCategory = (nodes: any, nodeId: string): any => {
    for (const node of nodes) {
      if (node.alias === nodeId) {
        return node;
      }
      if (node.categories) {
        const foundCategory = findCategory(node.categories, nodeId);
        if (foundCategory) {
          return foundCategory;
        }
      }
    }
    return null;
  };

  const handleConfirm = () => {
    onSelect(selectedCategory);
    setOpen(false);
  };

  const renderTree = (nodes: any) => {
    return (
      <TreeItem
        key={nodes.id}
        nodeId={nodes.alias}
        label={
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>{nodes.name}</span>
          </Box>
        }
      >
        {nodes.categories
          ? nodes.categories.map((node: any) => renderTree(node))
          : null}
      </TreeItem>
    );
  };
  return (
    <>
      <DialogTitle>Выберите {title}:</DialogTitle>
      <DialogContent sx={{ maxHeight: '500px' }}>
        {categories ? (
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            onNodeSelect={handleNodeSelect}
          >
            {categories?.map((item: any) => {
              return (
                <TreeItem key={item.id} nodeId={item.alias} label={item.name}>
                  {item.categories
                    ? item.categories.map((category: any) =>
                        renderTree(category)
                      )
                    : null}
                </TreeItem>
              );
            })}
          </TreeView>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          sx={{
            width: '60%',
            opacity: 1,
            backgroundColor: theme.palette.success.main,
            '&:hover': {
              backgroundColor: theme.palette.success.dark,
              color: theme.palette.grey[50],
              opacity: 1,
            },
          }}
          variant="contained"
          onClick={() => handleConfirm()}
          disabled={!selectedCategory || hasNestedCategories(selectedCategory)}
        >
          Подтвердить
        </Button>
        <Button
          size="small"
          sx={{
            width: '40%',
            backgroundColor: theme.palette.error.main,
            '&:hover': {
              backgroundColor: theme.palette.error.light,
              color: theme.palette.grey[50],
              opacity: 1,
            },
          }}
          color="error"
          variant="contained"
          onClick={() => setOpen(false)}
        >
          Закрыть
        </Button>
      </DialogActions>
    </>
  );
};
