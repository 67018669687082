import React, { useEffect, useState } from 'react';

import {
  Box,
  Typography,
  Drawer,
  Button,
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import theme from 'shared/theme';

type Anchor = 'right';

export const UI = ({
  children,
  btnTitle,
  title,
  background,
  color,
  margin,
  tooltip,
  tooltipTitle,
  position,
  close,
  padding,
  mr,
  mt,
  width,
  minWidth,
  height,
  boxShadow,
  variant,
  size,
  fontSize,
  flexBasis,
  openDrawer,
  display,
  borderRadius,
  fontWeight,
}: any) => {
  const anchor: Anchor = position ? position : 'right';
  const [state, setState] = useState({
    right: false,
  });
  useEffect(() => {
    setState({ ...state, [anchor]: false });
  }, [close]);

  useEffect(() => {
    setState({
      ...state,
      [anchor]: openDrawer,
    });
  }, [anchor, openDrawer]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  return (
    <Grid container>
      {tooltip ? (
        <Tooltip title={tooltipTitle} arrow>
          <Button
            sx={{
              display: display && display,
              width: '18px',
              minWidth: minWidth ? minWidth : '20px',
              height: height ? height : 'auto',
              textAlign: 'center',
              boxShadow: boxShadow ? boxShadow : 'auto',
              borderRadius: borderRadius,
              p: padding && padding,
              mb: margin,
              color: color,
              background: background,
              '@media (max-width: 768px)': {
                // p: padding ? padding : 0.5,
              },
              ':hover': {
                boxShadow: boxShadow ? boxShadow : 'auto',
              },
            }}
            size={size ? size : 'small'}
            variant={variant ? variant : 'contained'}
            onClick={toggleDrawer(anchor, true)}
          >
            {btnTitle}
          </Button>
        </Tooltip>
      ) : (
        <Button
          sx={
            variant
              ? {
                  display: display ? display : 'block',
                  background: 'none',
                  color: 'orange',
                  fontSize: 25,
                  border: '1px solid red',
                }
              : {
                  display: display ? display : 'block',
                  width: width ? width : '100%',
                  minWidth: minWidth ? minWidth : '20px',
                  height: height ? height : 'auto',
                  // p: padding ? padding : 1.5,
                  boxShadow: boxShadow ? boxShadow : 'auto',
                  mb: margin,
                  color: color,
                  background: background,
                  mr: mr,
                  mt: mt,
                  borderRadius: borderRadius,
                  fontWeight: fontWeight,
                  fontSize: fontSize || '13px',
                  [theme.breakpoints.down('sm')]: { mr: 0 },
                  '@media (max-width: 768px)': {
                    // p: 0.5,
                  },
                  ':hover': {
                    boxShadow: boxShadow ? boxShadow : 'auto',
                  },
                }
          }
          size={size ? size : 'small'}
          variant={variant ? variant : 'contained'}
          onClick={toggleDrawer(anchor, true)}
        >
          {btnTitle}
        </Button>
      )}

      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        <Box
          sx={{
            width: '50vw',
            height: '100%',
            p: 4,
            '@media (max-width: 768px)': {
              width: '70vw',
              p: 2,
            },
            '@media (max-width: 425px)': {
              width: '100vw',
              p: 2,
            },
          }}
          role="presentation"
        >
          <Box sx={{ pt: 1 }}>
            <Grid container>
              <Grid
                item
                container
                sx={{
                  alignContent: 'center',
                  alignItems: 'center',
                  mb: 3,
                  justifyContent: 'space-between',
                }}
              >
                <Grid
                  item
                  xs={10}
                  sx={{
                    '@media (max-width: 425px)': { maxWidth: '250px' },
                  }}
                >
                  {title ? (
                    <Typography
                      variant="h5"
                      sx={{
                        [theme.breakpoints.down('sm')]: { fontSize: '19px' },
                      }}
                    >
                      {title}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="close drawer"
                    sx={{ cursor: 'pointer' }}
                    onClick={toggleDrawer(anchor, false)}
                  >
                    <ClearIcon
                      sx={{
                        color: theme.palette.grey[600],
                        fontSize: 24,
                        '@media (max-width: 425px)': {
                          fontSize: '15px',
                        },
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ flexBasis: flexBasis || null }}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Drawer>
    </Grid>
  );
};
