import { MenuItem } from '@mui/material';
import { capitalizeFirstLetter } from '.';

export const renderItem = (items = [], level = 1): any => {
  return items.map((item: any) => {
    const paddingLeft = level * 20;
    return [
      <MenuItem key={item.value} value={item.value} style={{ paddingLeft }}>
        {capitalizeFirstLetter(item.text)}
      </MenuItem>,
      item.categories && renderItem(item.categories, level + 1),
    ];
  });
};
